import { RouteObject } from "react-router-dom";
import {ROUTES} from "../models/routes";
import Home from "../pages/home/Home";

export const ROUTES_LIST: RouteObject[] = [
  {
    path: ROUTES.HOME,
    element: <Home/>
  },
  {
    path: "/:coachUsername",
    element: <Home />  // Same component as home, but will read the URL param
  }
]
